.nodata-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .img-nodata{
        background-size:contain;
        background-repeat: no-repeat;
        width: 600px;
        height: 600px;
    }

}