.form-changepass-wrapper{
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 60px;
    padding: 20px 42px;

    .datos-user-changepass{
        margin-bottom: 40px;
        font-family: "Titillium Web", sans-serif;
        text-align: center;
        
        p{
            font-size: 1.2rem;
            color: $teal;

            span{
                font-size: 1.1rem;
                color: black;
                padding-left: 10px; 
            }
        }
    }

    @include input-element();
    @include label-element();
    
    .one-column-center{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
    }

    
    .titulo-form{
     font-family: 'Titillium Web', sans-serif;
     margin-top: 0px;
     margin-bottom: 30px;
 
    }

    .error-change-pass{
        color: $warning;
        text-align: center;
        font-size: 1.3rem;
        font-weight: bold;
        margin-bottom: 20px;
    }
    .boton-wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
    
        .boton{
            @include base-boton();
            width: 200px;
        }
    } 

 }