.login-container{
    display: flex;
    justify-content: center;
    align-items: center;

    .auth-wrapper{
        display: grid;
        grid-template-columns: 1fr 1fr;
        height: calc(70vh - 90px);
        width: 80vw;
        margin-top: 120px;
        border: 1px transparent;
        border-radius: 5px;
        

        .img-login{
            background-size:cover;
        }

        .login-form{
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $whiteligth;
        } 

        .login-element{
            @include input-element();
            @include label-element();
        }

        .error-login{
            color: $warning;
            font-size: 1.3rem;
            font-weight: bold;
            margin-bottom: 20px;
        }

        .boton-wrapper{
            display: flex;
            align-items: center;
            justify-content: center;
        
            .boton{
                @include base-boton();
                width: 200px;
            }
        }
    }
}