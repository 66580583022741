.nomatch-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .img-nomatch{
        background-size:contain;
        background-repeat: no-repeat;
        width: 700px;
        height: 700px;
    }


    .link-nomatch-401{ 
    margin-top: 10px;
    
        a{
            text-decoration: none;
            color: $greenAccept;
            font-size: 2rem;
            font-weight: bold;
        }
    }
    
    .link-nomatch-404{
        margin-top: 10px;
    
        a{
            text-decoration: none;
            color: blue;
            font-size: 2rem;
            font-weight: bold;
        }
    }
}