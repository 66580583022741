//Colores a usar en la app
$black-letras: #3C4858;
$teal: #26bfd4;
$dark-teal: #207b88;
$tealdark: #0cd9f4;
$offwhite: #f6f6f6;
$blueespecial: #008dff;
$grey: #8a8a8a;
$whiteligth: #d4eef2;
$greyligth : #eeeeee;
$greenAccept: #5cb860;
$warning: #AD1519;