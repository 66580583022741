.form-viewuser-wrapper{
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 60px;
    padding: 20px 42px;
 
    @include input-element();
    @include label-element();
 
    .titulo-form{
     font-family: 'Titillium Web', sans-serif;
     margin-top: 0px;
     margin-bottom: 30px;
 
    }

    .column-left{
        margin-bottom: 21px;
        font-family: "Titillium Web", sans-serif;
        font-weight: 600;
        text-align: right;
        font-size: 1.2rem;
        color: $teal;
    }

    .column-right{
        margin-bottom: 21px;
        font-family: "Titillium Web", sans-serif;
        font-weight: 500;
        font-size: 1.2rem;
        // color: $teal;
    }
 }