.one-column {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 21px;
}

.two-column {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 21px;
}
