.head {
  display: flex;
  text-align: center;
  justify-content: center;
  font-family: "Dancing Script";
  font-size: 1.2rem;
}

body {
  margin: 0px;
  font-family: "Open Sans", sans-serif;
}

.nav-containter {
  display: grid;
  grid-template-columns: 1fr 7fr;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-container .auth-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: calc(70vh - 90px);
  width: 80vw;
  margin-top: 120px;
  border: 1px transparent;
  border-radius: 5px;
}
.login-container .auth-wrapper .img-login {
  background-size: cover;
}
.login-container .auth-wrapper .login-form {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d4eef2;
}
.login-container .auth-wrapper .login-element input {
  font-family: "Titillium Web", sans-serif;
  color: black;
  padding: 5px 0px;
  width: 100%;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  border-bottom: 1px solid #26bfd4;
  background-color: transparent;
  transition: 0.5s all ease-in-out;
  font-size: 1.15em;
  margin-bottom: 21px;
}
.login-container .auth-wrapper .login-element input:focus {
  outline: none;
  border-bottom: 1px solid #207b88;
}
.login-container .auth-wrapper .login-element input::file-selector-button {
  font-weight: bold;
  color: dodgerblue;
  padding: 0.5em;
  border: thin solid grey;
  border-radius: 3px;
  opacity: 0.5;
}
.login-container .auth-wrapper .login-element label {
  font-family: "Titillium Web", sans-serif;
  font-weight: 500;
  color: #26bfd4;
}
.login-container .auth-wrapper .error-login {
  color: #AD1519;
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 20px;
}
.login-container .auth-wrapper .boton-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-container .auth-wrapper .boton-wrapper .boton {
  cursor: pointer;
  height: 42px;
  font-size: 1.1em;
  font-weight: 500;
  border: 1px solid transparent;
  transition: all 0.5s ease-in-out;
  background-color: #26bfd4;
  color: white;
  width: 200px;
}
.login-container .auth-wrapper .boton-wrapper .boton:active, .login-container .auth-wrapper .boton-wrapper .boton:focus {
  outline: none;
}
.login-container .auth-wrapper .boton-wrapper .boton:hover {
  background-color: #207b88;
}

.nav-wrapper {
  height: calc(100vh - 98px);
  border-right: 1px solid black;
}
.nav-wrapper .logo-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.nav-wrapper .logo-navigation img {
  width: 120px;
  height: 50px;
}
.nav-wrapper .user-activo {
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav-wrapper .user-activo .user-icon {
  padding-right: 10px;
  font-size: 1.1rem;
}
.nav-wrapper .nav-link-wrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 10px 15px;
  color: #3C4858;
  text-decoration: none;
  transition: 0.5 ease-in-out;
}
.nav-wrapper .nav-link-wrapper:hover {
  background: rgba(200, 200, 200, 0.2);
  border-radius: 5px;
}
.nav-wrapper .nav-link-wrapper .icon-dropdown {
  margin-left: auto;
}
.nav-wrapper .nav-link-wrapper:focus-within {
  background: #26bfd4;
  border-radius: 5px;
  font-weight: bold;
}
.nav-wrapper .nav-link-wrapper .icon-navigation {
  padding-right: 10px;
  padding-bottom: 5px;
  font-size: 1.1rem;
}
.nav-wrapper .nav-link-wrapper a {
  text-decoration: none;
  color: #3C4858;
}
.nav-wrapper .logout-wrapper {
  cursor: pointer;
}

.rdt_TableCol {
  background: #26bfd4;
  font-size: 1.2rem;
  font-weight: bold;
}

.rdt_TableCell {
  font-size: 1rem;
}

.download-documento-wrapper {
  display: flex;
  align-items: center;
  transition: 0.5s all ease-in-out;
  cursor: pointer;
}
.download-documento-wrapper .Icon-download-documento {
  font-size: 1.3rem;
  margin-right: 8px;
}
.download-documento-wrapper:hover {
  color: green;
  font-size: 1.05rem;
}

.Icon-datatable-red {
  font-size: 1.3rem;
  cursor: pointer;
  transition: 0.5s all ease-in-out;
}
.Icon-datatable-red:hover {
  font-size: 1.5rem;
  color: #AD1519;
}

.Icon-datatable-green {
  font-size: 1.3rem;
  cursor: pointer;
  transition: 0.5s all ease-in-out;
}
.Icon-datatable-green:hover {
  font-size: 1.5rem;
  color: green;
}

.Icon-datatable-teal {
  font-size: 1.3rem;
  cursor: pointer;
  transition: 0.5s all ease-in-out;
}
.Icon-datatable-teal:hover {
  font-size: 1.5rem;
  color: #207b88;
}

.one-column {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 21px;
}

.two-column {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 21px;
}

.base-modulos {
  margin: 20px;
}
.base-modulos .head-container-facturas {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.base-modulos .head-container-facturas .container-icon-facturas {
  display: flex;
  margin-right: 5px;
  align-items: center;
}
.base-modulos .head-container-facturas .container-icon-facturas p {
  order: 1;
  font-weight: bold;
  font-size: 1.2rem;
  opacity: 0;
  padding-right: 10px;
  transition: ease-in-out 1.5s;
}
.base-modulos .head-container-facturas .container-icon-facturas .icon-upload-ingreso {
  order: 2;
  color: #5cb860;
  font-size: 3rem;
  cursor: pointer;
  transition: ease-in-out 1.5s;
}
.base-modulos .head-container-facturas .container-icon-facturas .icon-upload-ingreso:hover {
  color: green;
}
.base-modulos .head-container-facturas .container-icon-facturas .icon-upload-ingreso:hover ~ p {
  opacity: 1;
}

.form-addfactura-wrapper {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 60px;
  padding: 42px;
}
.form-addfactura-wrapper input {
  font-family: "Titillium Web", sans-serif;
  color: black;
  padding: 5px 0px;
  width: 100%;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  border-bottom: 1px solid #26bfd4;
  background-color: transparent;
  transition: 0.5s all ease-in-out;
  font-size: 1.15em;
  margin-bottom: 21px;
}
.form-addfactura-wrapper input:focus {
  outline: none;
  border-bottom: 1px solid #207b88;
}
.form-addfactura-wrapper input::file-selector-button {
  font-weight: bold;
  color: dodgerblue;
  padding: 0.5em;
  border: thin solid grey;
  border-radius: 3px;
  opacity: 0.5;
}
.form-addfactura-wrapper label {
  font-family: "Titillium Web", sans-serif;
  font-weight: 500;
  color: #26bfd4;
}
.form-addfactura-wrapper select {
  border: 2px solid #26bfd4;
  border-radius: 3px;
  width: 150px;
  height: 25px;
  box-sizing: border-box;
  margin-left: 30px;
  color: #3C4858;
  font-size: 1rem;
}
.form-addfactura-wrapper .payment-img {
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;
  height: 700px;
  width: 550px;
}
.form-addfactura-wrapper .boton-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.form-addfactura-wrapper .boton-wrapper .boton {
  cursor: pointer;
  height: 42px;
  font-size: 1.1em;
  font-weight: 500;
  border: 1px solid transparent;
  transition: all 0.5s ease-in-out;
  background-color: #26bfd4;
  color: white;
  width: 200px;
}
.form-addfactura-wrapper .boton-wrapper .boton:active, .form-addfactura-wrapper .boton-wrapper .boton:focus {
  outline: none;
}
.form-addfactura-wrapper .boton-wrapper .boton:hover {
  background-color: #207b88;
}

.form-editfactura-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 60px;
  padding: 20px 42px;
}
.form-editfactura-wrapper input {
  font-family: "Titillium Web", sans-serif;
  color: black;
  padding: 5px 0px;
  width: 100%;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  border-bottom: 1px solid #26bfd4;
  background-color: transparent;
  transition: 0.5s all ease-in-out;
  font-size: 1.15em;
  margin-bottom: 21px;
}
.form-editfactura-wrapper input:focus {
  outline: none;
  border-bottom: 1px solid #207b88;
}
.form-editfactura-wrapper input::file-selector-button {
  font-weight: bold;
  color: dodgerblue;
  padding: 0.5em;
  border: thin solid grey;
  border-radius: 3px;
  opacity: 0.5;
}
.form-editfactura-wrapper label {
  font-family: "Titillium Web", sans-serif;
  font-weight: 500;
  color: #26bfd4;
}
.form-editfactura-wrapper select {
  border: 2px solid #26bfd4;
  border-radius: 3px;
  width: 150px;
  height: 25px;
  box-sizing: border-box;
  margin-left: 30px;
  color: #3C4858;
  font-size: 1rem;
}
.form-editfactura-wrapper .titulo-form {
  font-family: "Titillium Web", sans-serif;
  margin-top: 0px;
  margin-bottom: 30px;
}
.form-editfactura-wrapper .boton-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.form-editfactura-wrapper .boton-wrapper .boton {
  cursor: pointer;
  height: 42px;
  font-size: 1.1em;
  font-weight: 500;
  border: 1px solid transparent;
  transition: all 0.5s ease-in-out;
  background-color: #26bfd4;
  color: white;
  width: 200px;
}
.form-editfactura-wrapper .boton-wrapper .boton:active, .form-editfactura-wrapper .boton-wrapper .boton:focus {
  outline: none;
}
.form-editfactura-wrapper .boton-wrapper .boton:hover {
  background-color: #207b88;
}

.home-container {
  width: 100%;
  height: 100%;
}
.home-container .home-wrapper {
  background: #26bfd4;
  height: 55%;
  margin: 100px;
  padding: 100px;
}
.home-container .home-wrapper .home-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #eeeeee;
  height: 100%;
}
.home-container .home-wrapper .home-content .home-img {
  background-size: contain;
  background-repeat: no-repeat;
  width: 250px;
  height: 100px;
}

.title-impuestos {
  background-color: #26bfd4;
  padding: 10px;
  color: black;
  font-size: 1.4rem;
}

.table-grid-impuestos {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 30px;
  margin-left: 10px;
}
.table-grid-impuestos .title-table-grid-impuestos {
  font-size: 1.2rem;
  color: #3C4858;
  font-weight: 600;
  text-decoration: underline;
  margin-bottom: 40px;
}
.table-grid-impuestos .data-table-grid-impuestos {
  margin-bottom: 30px;
  font-size: 1.1rem;
}
.table-grid-impuestos .data-table-grid-impuestos select {
  border: 2px solid #26bfd4;
  border-radius: 3px;
  width: 150px;
  height: 25px;
  box-sizing: border-box;
  margin-left: 30px;
  color: #3C4858;
  font-size: 1rem;
}
.table-grid-impuestos .data-table-grid-impuestos select {
  width: 50px;
}
.table-grid-impuestos .data-table-grid-impuestos span {
  padding-left: 10px;
  color: black;
  font-size: 1.2rem;
  font-weight: bold;
}

.base-gestion-users {
  margin: 25px;
}
.base-gestion-users .head-container-gestion-users {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.base-gestion-users .head-container-gestion-users .container-icon-users {
  display: flex;
  margin-right: 5px;
  align-items: center;
}
.base-gestion-users .head-container-gestion-users .container-icon-users p {
  order: 1;
  font-weight: bold;
  font-size: 1.2rem;
  opacity: 0;
  padding-right: 10px;
  transition: ease-in-out 1.5s;
}
.base-gestion-users .head-container-gestion-users .container-icon-users .icon-add-user {
  order: 2;
  color: #5cb860;
  font-size: 3rem;
  cursor: pointer;
  transition: ease-in-out 1.5s;
}
.base-gestion-users .head-container-gestion-users .container-icon-users .icon-add-user:hover {
  color: green;
}
.base-gestion-users .head-container-gestion-users .container-icon-users .icon-add-user:hover ~ p {
  opacity: 1;
}
.base-gestion-users .table-gestion-users {
  margin: 10px 150px;
}

.base-trabajo-user {
  margin: 25px;
}
.base-trabajo-user .head-container-trabajo-user {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.base-trabajo-user .table-trabajo-user {
  margin: 10px 40px;
}

.table-grid-trabajo-user {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
}
.table-grid-trabajo-user .table-grid-user-select {
  margin-left: 50px;
  border: 2px solid #26bfd4;
  border-radius: 4px;
}
.table-grid-trabajo-user .table-grid-user-select .table-trabajo-text-heading {
  font-size: 1.5rem;
  color: black;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 50px;
  text-align: center;
}
.table-grid-trabajo-user .table-grid-user-select .table-trabajo-data-user {
  margin-left: 30px;
}
.table-grid-trabajo-user .table-grid-user-select .table-trabajo-data-user p {
  font-size: 1.2rem;
  color: #26bfd4;
  font-weight: 500;
}
.table-grid-trabajo-user .table-grid-user-select .table-trabajo-data-user span {
  font-size: 1.15rem;
  color: #3C4858;
  padding-left: 10px;
}
.table-grid-trabajo-user .table-grid-user-select .boton-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 30px;
}
.table-grid-trabajo-user .table-grid-user-select .boton-wrapper .boton {
  cursor: pointer;
  height: 42px;
  font-size: 1.1em;
  font-weight: 500;
  border: 1px solid transparent;
  transition: all 0.5s ease-in-out;
  background-color: #26bfd4;
  color: white;
  width: 200px;
}
.table-grid-trabajo-user .table-grid-user-select .boton-wrapper .boton:active, .table-grid-trabajo-user .table-grid-user-select .boton-wrapper .boton:focus {
  outline: none;
}
.table-grid-trabajo-user .table-grid-user-select .boton-wrapper .boton:hover {
  background-color: #207b88;
}

.form-viewuser-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 60px;
  padding: 20px 42px;
}
.form-viewuser-wrapper input {
  font-family: "Titillium Web", sans-serif;
  color: black;
  padding: 5px 0px;
  width: 100%;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  border-bottom: 1px solid #26bfd4;
  background-color: transparent;
  transition: 0.5s all ease-in-out;
  font-size: 1.15em;
  margin-bottom: 21px;
}
.form-viewuser-wrapper input:focus {
  outline: none;
  border-bottom: 1px solid #207b88;
}
.form-viewuser-wrapper input::file-selector-button {
  font-weight: bold;
  color: dodgerblue;
  padding: 0.5em;
  border: thin solid grey;
  border-radius: 3px;
  opacity: 0.5;
}
.form-viewuser-wrapper label {
  font-family: "Titillium Web", sans-serif;
  font-weight: 500;
  color: #26bfd4;
}
.form-viewuser-wrapper .titulo-form {
  font-family: "Titillium Web", sans-serif;
  margin-top: 0px;
  margin-bottom: 30px;
}
.form-viewuser-wrapper .column-left {
  margin-bottom: 21px;
  font-family: "Titillium Web", sans-serif;
  font-weight: 600;
  text-align: right;
  font-size: 1.2rem;
  color: #26bfd4;
}
.form-viewuser-wrapper .column-right {
  margin-bottom: 21px;
  font-family: "Titillium Web", sans-serif;
  font-weight: 500;
  font-size: 1.2rem;
}

.form-changepass-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 60px;
  padding: 20px 42px;
}
.form-changepass-wrapper .datos-user-changepass {
  margin-bottom: 40px;
  font-family: "Titillium Web", sans-serif;
  text-align: center;
}
.form-changepass-wrapper .datos-user-changepass p {
  font-size: 1.2rem;
  color: #26bfd4;
}
.form-changepass-wrapper .datos-user-changepass p span {
  font-size: 1.1rem;
  color: black;
  padding-left: 10px;
}
.form-changepass-wrapper input {
  font-family: "Titillium Web", sans-serif;
  color: black;
  padding: 5px 0px;
  width: 100%;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  border-bottom: 1px solid #26bfd4;
  background-color: transparent;
  transition: 0.5s all ease-in-out;
  font-size: 1.15em;
  margin-bottom: 21px;
}
.form-changepass-wrapper input:focus {
  outline: none;
  border-bottom: 1px solid #207b88;
}
.form-changepass-wrapper input::file-selector-button {
  font-weight: bold;
  color: dodgerblue;
  padding: 0.5em;
  border: thin solid grey;
  border-radius: 3px;
  opacity: 0.5;
}
.form-changepass-wrapper label {
  font-family: "Titillium Web", sans-serif;
  font-weight: 500;
  color: #26bfd4;
}
.form-changepass-wrapper .one-column-center {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.form-changepass-wrapper .titulo-form {
  font-family: "Titillium Web", sans-serif;
  margin-top: 0px;
  margin-bottom: 30px;
}
.form-changepass-wrapper .error-change-pass {
  color: #AD1519;
  text-align: center;
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 20px;
}
.form-changepass-wrapper .boton-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.form-changepass-wrapper .boton-wrapper .boton {
  cursor: pointer;
  height: 42px;
  font-size: 1.1em;
  font-weight: 500;
  border: 1px solid transparent;
  transition: all 0.5s ease-in-out;
  background-color: #26bfd4;
  color: white;
  width: 200px;
}
.form-changepass-wrapper .boton-wrapper .boton:active, .form-changepass-wrapper .boton-wrapper .boton:focus {
  outline: none;
}
.form-changepass-wrapper .boton-wrapper .boton:hover {
  background-color: #207b88;
}

.base-documentos {
  margin: 20px;
}
.base-documentos .head-container-documentos {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.base-documentos .head-container-documentos .container-icon-documento {
  display: flex;
  margin-right: 5px;
  align-items: center;
}
.base-documentos .head-container-documentos .container-icon-documento p {
  order: 1;
  font-weight: bold;
  font-size: 1.2rem;
  opacity: 0;
  padding-right: 10px;
  transition: ease-in-out 1.5s;
}
.base-documentos .head-container-documentos .container-icon-documento .icon-add-documento {
  order: 2;
  color: #5cb860;
  font-size: 3rem;
  cursor: pointer;
  transition: ease-in-out 1.5s;
}
.base-documentos .head-container-documentos .container-icon-documento .icon-add-documento:hover {
  color: green;
}
.base-documentos .head-container-documentos .container-icon-documento .icon-add-documento:hover ~ p {
  opacity: 1;
}
.base-documentos .table-documentos {
  margin: 10px 50px;
}

.form-adddocumento-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  padding: 20px 42px;
}
.form-adddocumento-wrapper input {
  font-family: "Titillium Web", sans-serif;
  color: black;
  padding: 5px 0px;
  width: 100%;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  border-bottom: 1px solid #26bfd4;
  background-color: transparent;
  transition: 0.5s all ease-in-out;
  font-size: 1.15em;
  margin-bottom: 21px;
}
.form-adddocumento-wrapper input:focus {
  outline: none;
  border-bottom: 1px solid #207b88;
}
.form-adddocumento-wrapper input::file-selector-button {
  font-weight: bold;
  color: dodgerblue;
  padding: 0.5em;
  border: thin solid grey;
  border-radius: 3px;
  opacity: 0.5;
}
.form-adddocumento-wrapper label {
  font-family: "Titillium Web", sans-serif;
  font-weight: 500;
  color: #26bfd4;
}
.form-adddocumento-wrapper input {
  margin-bottom: 70px;
}
.form-adddocumento-wrapper .titulo-form {
  font-family: "Titillium Web", sans-serif;
  margin-top: 0px;
  margin-bottom: 30px;
}
.form-adddocumento-wrapper .boton-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.form-adddocumento-wrapper .boton-wrapper .boton {
  cursor: pointer;
  height: 42px;
  font-size: 1.1em;
  font-weight: 500;
  border: 1px solid transparent;
  transition: all 0.5s ease-in-out;
  background-color: #26bfd4;
  color: white;
  width: 200px;
}
.form-adddocumento-wrapper .boton-wrapper .boton:active, .form-adddocumento-wrapper .boton-wrapper .boton:focus {
  outline: none;
}
.form-adddocumento-wrapper .boton-wrapper .boton:hover {
  background-color: #207b88;
}

.nomatch-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.nomatch-wrapper .img-nomatch {
  background-size: contain;
  background-repeat: no-repeat;
  width: 700px;
  height: 700px;
}
.nomatch-wrapper .link-nomatch-401 {
  margin-top: 10px;
}
.nomatch-wrapper .link-nomatch-401 a {
  text-decoration: none;
  color: #5cb860;
  font-size: 2rem;
  font-weight: bold;
}
.nomatch-wrapper .link-nomatch-404 {
  margin-top: 10px;
}
.nomatch-wrapper .link-nomatch-404 a {
  text-decoration: none;
  color: blue;
  font-size: 2rem;
  font-weight: bold;
}

.nodata-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.nodata-wrapper .img-nodata {
  background-size: contain;
  background-repeat: no-repeat;
  width: 600px;
  height: 600px;
}