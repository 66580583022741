.home-container {
    width: 100%;
    height: 100%;
    //padding: 100px 150px;

    .home-wrapper{
        background: $teal;
        height: 55%;
        margin: 100px;
        padding: 100px;

        .home-content{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            background: $greyligth;
            height: 100%;
           
            .home-img{
                background-size:contain;
                background-repeat: no-repeat;
                width: 250px;
                height: 100px;
            }
            
           
            
        }
        
    }
}