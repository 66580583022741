@mixin base-boton{
    cursor: pointer;
    height: 42px;
    font-size: 1.1em;
    font-weight: 500;
    border: 1px solid transparent;
    transition: all 0.5s ease-in-out;
    background-color: $teal;
    color: white;


    &:active, &:focus {
        outline: none;
    }

    &:hover {
        background-color: $dark-teal;
    }
}

@mixin input-element {
    input{
        font-family: "Titillium Web", sans-serif;
        color: black;
        padding: 5px 0px;
        width: 100%;
        border-top: 0px;
        border-right: 0px;
        border-left: 0px;
        border-bottom: 1px solid $teal;
        background-color: transparent;
        transition: 0.5s all ease-in-out;
        font-size: 1.15em;
        margin-bottom: 21px;

        &:focus {
            outline: none;
            border-bottom: 1px solid $dark-teal;
        }
    }

    input::file-selector-button {
        font-weight: bold;
        color: dodgerblue;
        padding: 0.5em;
        border: thin solid grey;
        border-radius: 3px;
        opacity: 0.5;
      }
}

@mixin label-element{
    label{
        font-family: "Titillium Web", sans-serif;
        font-weight: 500;
        // font-size: 1.1rem;
        color: $teal;
    }
}

@mixin input-select{
 
    select{
        border: 2px solid $teal;
        border-radius: 3px;
        width: 150px;
        height: 25px;
        // padding: 0;
        box-sizing: border-box;
        margin-left: 30px;
        color: $black-letras;
        font-size: 1rem; 
    }
}


@mixin icon-datatable{
    font-size: 1.3rem;
    cursor: pointer;
    transition: 0.5s all ease-in-out;
}